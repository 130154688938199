import styled, { css } from "styled-components";
import { COMMON } from "@sproutsocial/seeds-react-system-props";
import { focusRing } from "@sproutsocial/seeds-react-mixins";
import { TypeInputContainerProps } from "./InputTypes";

const Container = styled.div<TypeInputContainerProps>`
  box-sizing: border-box;
  position: relative;

  input {
    box-sizing: border-box;
    width: 100%;
    border: 1px solid transparent;
    border-radius: ${(props) => props.theme.radii[500]};
    background-color: ${(props) => props.theme.colors.form.background.base};
    color: ${(props) => props.theme.colors.text.body};
    outline: none;
    transition: border-color ${(props) => props.theme.duration.fast}
        ${(props) => props.theme.easing.ease_in},
      box-shadow ${(props) => props.theme.duration.fast}
        ${(props) => props.theme.easing.ease_in};
    font-family: ${(props) => props.theme.fontFamily};
    font-weight: ${(props) => props.theme.fontWeights.normal};
    appearance: none;
    /** This matches the overall height of Button and Select */
    margin: 0;

    padding: ${(props) => {
      switch (props.size) {
        case "large":
          return `${props.theme.space[350]} ${props.theme.space[400]}`;

        case "small":
          return props.theme.space[200];

        case "default":
        default:
          return props.theme.space[300];
      }
    }};

    font-size: ${(props) => {
      switch (props.size) {
        case "large":
          return props.theme.typography[300].fontSize;

        case "small":
        case "default":
        default:
          return props.theme.typography[200].fontSize;
      }
    }};

    line-height: ${(props) => {
      switch (props.size) {
        case "large":
          return props.theme.typography[300].lineHeight;

        /* 16px was the value prior to this change to 'large'. Leaving as-is but it is a non-Seeds typography line-height. It may have a big impact though */
        case "small":
        case "default":
        default:
          return "16px";
      }
    }};

    /* https://stackoverflow.com/questions/14007655/remove-ie10s-clear-field-x-button-on-certain-inputs */
    &::-ms-clear {
      display: none;
    }

    &::-webkit-search-cancel-button {
      appearance: none;
    }

    &:focus {
      ${focusRing}
    }

    /* Fix for red ring when input is marked required in Firefox */
    &:not(output):not(:focus):-moz-ui-invalid {
      box-shadow: none;
    }

    &::placeholder {
      color: ${(props) => props.theme.colors.form.placeholder.base};
      font-style: italic;
    }

    ${(props) =>
      props.hasBeforeElement &&
      css`
        padding-left: 40px;
      `}

    ${(props) =>
      props.hasAfterElement &&
      css`
        padding-right: 40px;
      `}
  }

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.4;

      input {
        cursor: not-allowed;
      }
    `}

  ${(props) =>
    props.appearance === "primary" &&
    css`
      input {
        border: 1px solid ${(props) => props.theme.colors.form.border.base};
      }
    `}

  ${(props) =>
    props.invalid &&
    css`
      input {
        border-color: ${(props) => props.theme.colors.form.border.error};
      }
    `}

	${(props) =>
    props.warning &&
    css`
      input {
        border-color: ${(props) => props.theme.colors.form.border.warning};
      }
    `}

  ${COMMON}
`;

export const Accessory = styled.div<{
  before?: boolean;
  after?: boolean;
  isClearButton?: boolean;
}>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: ${(props) => props.theme.colors.icon.base};
  display: flex;
  align-items: center;

  ${(props) =>
    props.before &&
    css`
      left: ${props.theme.space[300]};
    `};

  ${(props) =>
    props.after &&
    css`
      right: ${props.isClearButton ? 0 : props.theme.space[300]};
    `};
`;

Container.displayName = "InputContainer";
Accessory.displayName = "InputAccessory";

export default Container;
